import React from "react";
import theme from "theme";
import { Theme, Link, Icon, Text, Box, Section, Image, Strong, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaMountain, FaBinoculars, FaRegCompass, FaCloud, FaHelicopter } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Mtns Cloud Services
			</title>
			<meta name={"description"} content={"eCommerce and Cloud Computing Services in Sydney and the Blue Mountains.  Specializing in AWS cloud infrastructure, Shopify Applications, Payments Integration, B2B/Wholesale, Fulfilment/Shipping and Retail/POS"} />
			<meta property={"og:title"} content={"Mtns Cloud Services"} />
			<meta property={"og:description"} content={"eCommerce and Cloud Computing Services in Sydney and the Blue Mountains.  Specializing in AWS cloud infrastructure, Shopify Applications, Payments Integration, B2B/Wholesale, Fulfilment/Shipping and Retail/POS"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/OG-image.png?v=2021-09-22T11:33:13.239Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/favicon%2032x32.png?v=2021-09-22T11:33:41.498Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/270x270.png?v=2021-09-22T11:33:55.934Z"} />
		</Helmet>
		<Section padding="38px 0 38px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" display="flex" />
			<Icon
				category="fa"
				icon={FaMountain}
				margin="0px 0px 22px 0px"
				color="--dark"
				size="36px"
			/>
			<Link
				text-decoration-line="initial"
				hover-opacity=".8"
				width="20%"
				md-width="40%"
				sm-width="50%"
				href="/"
				display="flex"
			>
				<Text font="normal 700 14px/1.5 --fontFamily-googleFiraSans" color="#1064C7" transform="rotate(-90deg)" margin="0px 0px 0px 0px">
					mtns
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
					Cloud Services
				</Text>
			</Link>
			<Components.QuarklycommunityKitMobileSidePanel md-justify-content="flex-end">
				<Override slot="Children" display="flex" />
				<Override slot="Content" padding="0px 0px 0px 0px" md-margin="38px 0px 0px 0px" />
				<Override
					slot="Button Text"
					md-font="--lead"
					md-text-transform="uppercase"
					sm-margin="3px 6.3px 0px 0px"
					md-margin="1px 6.3px 0px 0px"
				/>
				<Box
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
					md-justify-content="center"
					md-padding="20px 0px 20px 0px"
					md-margin="0px 0px 0px 0px"
					display="flex"
				/>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section background="--color-darkL1" padding="0px 0 0px 0" quarkly-title="HeroBlock" md-flex-wrap="wrap">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="80px 0px 40px 0px"
				md-padding="40px 0px 40px 0px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				lg-padding="80px 32px 40px 0px"
				md-width="100%"
			>
				<Box
					lg-width="100%"
					display="flex"
					flex-direction="column"
					width="70%"
					justify-content="space-between"
					flex="1 1 0%"
				>
					<Text font="--headline3" color="--light" sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif" margin="0px 0px 0px 0px" />
					<Text margin="32px 0px 0px 0px" font="--lead" color="--light" md-padding="0px 0px 40px 0px">
						Cloud Computing Services in Sydney and the Blue Mountains
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Image
					src="https://images.unsplash.com/photo-1595780280738-47265b603516?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000"
					max-width="100%"
					min-height="600px"
					object-fit="cover"
					lg-min-height="500px"
					sm-min-height="400px"
				/>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				width="50%"
				align-items="center"
				border-style="solid"
				padding="80px 0px 80px 0px"
				md-padding="60px 0px 60px 0px"
				empty-border-width="1px"
				border-color="#C4C4C4"
				empty-border-style="solid"
				empty-border-color="LightGray"
				border-width="1px 0px 0px 0px"
				empty-min-height="64px"
				display="flex"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark" lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif">
					<Strong>
						Consulting Services
					</Strong>
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					background="--color-primary"
					padding="32px 24px 32px 24px"
				>
					We bring 25 years experience across eCommerce, Banking, Insurance and Defence industries across the globe.  Specializing in efficiency and automation so you can focus on areas of your business that deliver customer value.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaBinoculars}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Mtns Survey
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Purchasing a business?  We can perform a technical due diligence review, mapping IT assets, flagging risks and planning for transition and integration
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaRegCompass}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Mtns Guides
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Advisory for businesses of all sizes from SMBs looking to expand into eCommerce or streamline operations, or large corporations migrating to the cloud or optimising existing cloud infrastructure.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCloud}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Mtns Sherpa
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Let us do the heavy lifting! We can assist with implementing plans produced through Survey or Guides services, or provide staff augmentation to your projects
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaHelicopter}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Mtns Rescue
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Lost in the cloud or stuck in a valley of costs?  Strugging with integrations that don't quite work? We can review and remediate your technical assets to ensure you remain secure, compliant and in control
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" align-content="flex-start" />
		<Section padding="0px 0 48px 0" quarkly-title="Footer">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" />
			<Box
				flex-direction="column"
				align-items="flex-start"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				padding="32px 32px 32px 32px"
				display="flex"
				lg-width="100%"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="33.333%"
				background="--color-primary"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
				>
					Contact info
				</Text>
				<Text
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--lead"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
				>
					<Strong>
						Mtns Cloud Services
					</Strong>
					<br />
					ABN 49630102889
					<br />
					Suite 9 210 Macquarie Road
					<br />
					Springwood
					<br />
					NSW 2777
					<br />
					Australia
				</Text>
				<Link
					href="tel:+61413631690"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					margin="0px 0px 16px 0px"
				>
					+61-413-631-690
				</Link>
				<Link
					href="mailto:info@mtns.cloud"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					margin="0px 0px 32px 0px"
				>
					info@mtns.cloud
				</Link>
			</Box>
			<Box
				padding="32px 32px 32px 32px"
				background="--color-lightD1"
				display="flex"
				flex-direction="column"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="33.333%"
				empty-min-width="64px"
				empty-border-style="solid"
				align-items="flex-start"
				lg-width="100%"
			>
				<Link
					margin="0px 0px 27px 0px"
					href="#"
					text-decoration-line="initial"
					color="--dark"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_black.svg?v=2021-09-18T00:53:14.815Z) 100% 0px/30px no-repeat"
					font="--headline4"
				>
					About Us
				</Link>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--dark"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_black.svg?v=2021-09-18T00:53:14.815Z) 100% 0px/30px no-repeat"
					font="--headline4"
					margin="0px 0px 27px 0px"
				>
					FAQ
				</Link>
				<Link
					text-decoration-line="initial"
					color="--dark"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_black.svg?v=2021-09-18T00:53:14.815Z) 100% 0px/30px no-repeat"
					font="--headline4"
					margin="0px 0px 27px 0px"
					lg-margin="0px 0px 0px 0px"
					href="#"
				>
					Blog
				</Link>
			</Box>
			<Box
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				width="33.333%"
				flex-direction="column"
				border-color="--color-grey"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="32px 32px 32px 32px"
				lg-width="100%"
				border-width="1px 0px 0px 0px"
				align-items="flex-start"
				display="flex"
				border-style="solid"
			>
				<Text
					font="--headline4"
					color="--dark"
					lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
					sm-font="400 22px/1.2 &quot;Fira Sans&quot;, sans-serif"
					margin="0px 0px 16px 0px"
				>
					Subscribe to our newsletter and updates
				</Text>
				<Components.QuarklycommunityKitNetlifyForm
					sm-width="100%"
					border-width="0px 0px 1px 0px"
					border-style="solid"
					border-color="--color-grey"
					width="240px"
					md-width="360px"
				>
					<Override slot="Form" justify-content="center" align-items="center" display="flex" />
					<Box display="flex" align-items="center" justify-content="flex-start">
						<Input
							required
							border-color="--color-grey"
							border-width="0px"
							border-style="none"
							md-width="100%"
							name="Email"
							type="email"
							padding="6px 16px 6px 0px"
							placeholder="Type your email here..."
						/>
						<Button color="transparent" background="transparent" font="normal 400 0px/1.5 &quot;Fira Sans&quot;, sans-serif">
							<Icon category="md" icon={MdKeyboardArrowRight} color="--dark" />
							Button
						</Button>
					</Box>
				</Components.QuarklycommunityKitNetlifyForm>
			</Box>
		</Section>
		<Components.QuarklycommunityKitNetlifyForm />
		<Components.Tabs />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6143a05129a0a1001e6c835a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }"}
			</style>
		</RawHtml>
	</Theme>;
});